import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Default from "../components/Default"
import Page from "../components/Page";
import SearchResults from "../components/SearchResults";
import SearchLayout from "../components/SearchLayout";
import BasicPage from "../components/BasicPage";
import BasicWrap from "../components/BasicWrap";
import Updates from "../components/Updates";


function SiteRouter(props) {

    const { results = [], tags=[], options = {}, loading = true, canEdit = false, dark = false, toggleDark = () => { } } = props;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/search/:search_term" element={<SearchLayout dark={dark} toggleDark={toggleDark} options={options} loading={loading}><SearchResults /></SearchLayout>} />
                <Route path="/search" element={<SearchLayout dark={dark} toggleDark={toggleDark} options={options} loading={loading}><SearchResults /></SearchLayout>} />
                <Route path="/documents/*" element={<Default dark={dark} toggleDark={toggleDark} section="documents" results={results} loading={loading} options={options}><BasicPage canEdit={canEdit} section="documents" results={results} /></Default>} />
                <Route path="/newsletter/" element={<Default dark={dark} toggleDark={toggleDark} noback section="newsletter" tags={tags} results={results} loading={loading} options={options}><BasicWrap tags={tags} canEdit={canEdit} section="newsletter" results={results} /></Default>} />
                <Route path="/newsletter/*" element={<Default dark={dark} toggleDark={toggleDark} noback section="newsletter" tags={tags} results={results} loading={loading} options={options}><BasicPage canEdit={canEdit} section="newsletter" results={results} /></Default>} />

                <Route path="/updates/" element={<Default dark={dark} toggleDark={toggleDark} noback section="updates" results={results} loading={loading} options={options}><Updates canEdit={canEdit} section="updates" results={results} /></Default>} />
                <Route path="/updates/*" element={<Default dark={dark} toggleDark={toggleDark} noback section="updates" results={results} loading={loading} options={options}><BasicPage nosidebar canEdit={canEdit} section="updates" results={results} /></Default>} />

                <Route path="/repdocuments/*" element={<Default dark={dark} toggleDark={toggleDark} section="repdocuments" results={results} loading={loading} options={options}><BasicPage canEdit={canEdit} section="repdocuments" results={results} /></Default>} />
                <Route path="/tutorials/*" element={<Default dark={dark} toggleDark={toggleDark} section="tutorials" results={results} loading={loading} options={options}><Page canEdit={canEdit} section="tutorials" results={results} /></Default>} />
                <Route path="*" element={<Navigate to="/tutorials" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SiteRouter;
