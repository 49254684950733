import React, { useEffect, useState } from "react";
import { Menu, Space, Input, Row, Col, Button, Drawer } from 'antd';
import logolight from "../media/logo_light.png";
import logodark from "../media/logo_dark.png"
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import { MenuOutlined, BulbOutlined, BulbFilled } from '@ant-design/icons';


export default function Nav(props) {

    const { results = [], dark = false, toggleDark = () => { } } = props;


    const [open, setOpen] = useState(false);
    const menu = [
        { path: "/tutorials", label: "Tutorials" },
        { path: "/documents", label: "Downloads" },
        { path: "/newsletter", label: "Newsletters" },
        { path: "/updates", label: "Bookmanager Updates" },
        { path: "/repdocuments", label: "For Publishers & Reps" },
    ]


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [navigate])


    const getRoot = (url) => {

        const parts = url.split('/').filter(Boolean);

        return parts.length > 0 ? "/" + parts[0] : "";
    }



    const linkTo = (_url) => {
        // let _path = _url.replace(/.*\/\/[^\/]*/, '');
        // if (_path.substr(_path.length - 1, 1) !== "/") {
        //     _path = _path + "/";
        // }
        navigate(_url);

    }

    const drawMobileNav = () => {
        return (
            <div className="hide mobile_search_bg">
                <div style={{ "height": "50px" }} className="mobileWrapper">
                    <Row align="middle">
                        <Col flex={"180px"}>
                            <img alt="bookmanager" style={{ "marginLeft": "0px", "height": "43px" }} width={180} src={logodark} />
                        </Col>
                        <Col flex={"auto"}>
                            <div style={{ "float": "right" }}>

                                <Space>
                                    <Button type="text" onClick={() => toggleDark()} shape="circle" icon={(dark) ? <BulbOutlined className="lightbulb" /> : <BulbFilled className="lightbulb" />} />
                                    <Button onClick={() => setOpen(!open)} style={{ "color": "#fff" }} type="text"><MenuOutlined /></Button>
                                </Space>


                            </div>
                            <br clear="both" />
                        </Col>
                    </Row>
                </div>
                <div>
                    <div className="navH" />
                    <div style={{ "padding": "10px 10px" }}>
                        <SearchBar results={results} />
                    </div>
                </div>
            </div>
        )
    }

    const siteNav = (_mode = "horizontal") => {

        return (
            <Menu selectedKeys={[getRoot(location.pathname)]} style={{ "padding": "0px" }} mode={_mode} theme="dark" >
                {menu.map(item => {
                    return (<Menu.Item onClick={() => linkTo(item.path)} type="text" key={item.path}>{item.label}</Menu.Item>)
                })}
            </Menu>
        )
    }

    const drawMobileMenu = () => {
        return (
            <Drawer
                title={<div style={{ "height": "17px" }}></div>}
                placement="right"
                width={230}
                closable={false}
                onClose={() => setOpen(false)}
                bodyStyle={{ "padding": "20px", "backgroundColor": "#001529" }}
                visible={open}
            >

                {siteNav("vertical")}
            </Drawer>
        )

    }



    return (


        <div >
            {drawMobileNav()}
            {drawMobileMenu()}
            <div className="show">
                <div className="navWrapper" style={{}}>
                    <div className="float-flex">

                        <Space>
                            <img alt="bookmanager" style={{ "marginLeft": "0px", "height": "43px" }} width={180} src={logodark} />
                            <div style={{ "width": "650px" }}>
                                {siteNav()}
                            </div>
                            <div style={{ "width": "390px" }}><SearchBar results={results} /></div>
                        </Space>
                        <div style={{ "marginTop": "5px", "paddingRight": "20px" }}>
                            <Button type="text" onClick={() => toggleDark()} shape="circle" icon={(dark) ? <BulbOutlined className="lightbulb" /> : <BulbFilled className="lightbulb" />} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}