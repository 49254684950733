import { Col, Divider, Layout, message, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Html from "../utils/Html";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Paginate from "./Paginate";
import { apiCall } from "../utils/Api";
import Tags from "./Tags";
export default function BasicWrap(props) {

    const navigate = useNavigate();
    const { tags = [] } = props;
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const getNews = () => {
        apiCall("getNewsletters", { selectedTags: selectedTags }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setPaginate({ current: 1, pagesize: 10, offset: 0, total: _result.length });
            } else {
                message.error("There was an error.");
            }
        })
    }

    useEffect(getNews, [selectedTags]);

    const [paginate, setPaginate] = useState({
        current: 1,
        pagesize: 10,
        offset: 0,
        total: 0,
    });

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [paginate])


    const drawItem = (_item) => {
        return (
            <div id={_item.slug}>
                <Typography.Title level={4}>
                    <Row>
                        <Col flex="auto"><a onClick={() => navigate(_item.path)}><Html html={_item.post_title} /></a></Col>
                    </Row>
                </Typography.Title>
                <div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(_item.date).format("MMM Do, YYYY")}</em></div>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <Html html={_item.excerpt} />
                <br /><br />
            </div>
        )
    }

    const drawItems = () => {
        return results.slice(paginate.offset, paginate.offset + paginate.pagesize).map(item => drawItem(item));
    }

    return (
        <div style={{ "padding": "40px", "maxWidth": "1300px" }}>
            <Layout style={{ "padding": "0px" }} className="layout">
                <Layout.Sider style={{"background" : "none"}} className="typeNav" theme="light" width={300}>
                    <div style={{ "padding": "30px", "paddingRight": "20px", "paddingTop" : "5px" }}>
                        <h3>Tags</h3>
                      
                        <Tags tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                    </div>
                </Layout.Sider>
                <Layout.Content>
                    <Spin spinning={loading}>
                        <Typography.Title level={3}>
                            Newsletters
                        </Typography.Title>
                        <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                        <Divider />
                        {drawItems()}
                        <Divider />
                        <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                    </Spin>
                </Layout.Content>
            </Layout>
        </div>
    );
}