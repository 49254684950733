/*

Default Layout - Wraps sections with nav, footer etc

*/

import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, BackTop, Button, Spin } from 'antd';
import Nav from "./Nav";
import { useLocation, useNavigate } from "react-router-dom";
import { ucfirst } from "../utils/Utils";
import { CaretLeftFilled, LeftOutlined } from '@ant-design/icons';

export default function SearchLayout(props) {

    const { options = {}, dark = false, toggleDark = () => {} } = props;
    const [loading, setLoading] = useState(false);




    return (
        <>
            <BackTop>
                <Button type="primary" ghost shape="circle" size="large" />
            </BackTop>

            <Nav dark={dark} toggleDark={toggleDark} menu={options.menu} />

            <div className="show" style={{ "height": "70px" }} />
            <Layout>
                <Layout.Content>
                    <Spin wrapperClassName={(loading) ? "adjust" : ""} spinning={loading}>
                        <div className="paddingwrap" style={{ "height": "calc(100vh - 70px)", "margin": "0 auto", "padding": "0 35px" }}>
                            <div className="layoutWrapper" style={{ "marginBottom": "20px" }}>
                                {(!loading && React.cloneElement(props.children))}
                            </div>
                        </div>
                    </Spin>
                </Layout.Content>
            </Layout>


        </>
    );

}