/*
    ---- Api ----
    Provides structure to all the rest requests
*/

 const apiPath = "https://support.bookmanager.com/index.php/wp-json/bookmanager/api";
//const apiPath = "http://localhost/index.php/wp-json/bookmanager/api";


export const apiCall  = (_methodName, _args, _callback) =>{

        // put all the data into a form to post 
        
        var form_data = new FormData();
        form_data.append("method", _methodName);

        if(_args){
            for ( var key in _args ) {
                form_data.append(key, _args[key]);
            }
        }
     
        fetch((apiPath), { method: 'POST', body:form_data})
        .then(response => response.json())
        .then((r) => {
            // request is formatted, but there is an error
            if(r.error){
                // is error
                _callback(false, r)
            } else {
                // is success
                _callback("success", r)
            }
            
        }).catch((e)=>{
            _callback(false, e)
            // is error
        });


}


