import 'antd/dist/antd.css';
import "./App.css";
import "./Dark.css";

import { useState, useEffect } from "react";
import { BulbOutlined, BulbFilled } from '@ant-design/icons';
import { Spin } from "antd";
import { apiCall } from "./utils/Api";
import SiteRouter from "./utils/SiteRouter";
import Cookies from 'js-cookie';

function App() {

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState({});
  const [canEdit, setCanEdit] = useState(false);
  const [tags, setTags] = useState([]);


  const fetchTags = () => {
    apiCall("getTags", {}, (_status, _result) => {
      if (_status) {
        setTags(_result)
      }
      setLoading(false);
    })
  }
 
  // Fetch Data
  const fetchData = () => {

    apiCall("fetchData", {}, (_status, _result) => {
      if (_status) {
        setResults(_result);
      }
      fetchTags();
    })
  }


  useEffect(fetchData, []);

  const checkGateway = () => {
    apiCall("checkGateway", {}, (_status, _results) => {
      if (_status) {
        setCanEdit(_results.result);
      }
    })
  }

  useEffect(checkGateway, [])



  // Configure dark mode
  let _init_dark = ((Cookies.get("darkmode") === "true") ? true : false);
  const [dark, setDark] = useState(_init_dark);
  document.body.classList.toggle('dark', dark);

  const toggleDark = () => {
    document.body.classList.toggle('dark', !dark);
    Cookies.set("darkmode", !dark, { expires: 365 });
    setDark(!dark);
  }


  // Site Routes / Dark Mode toggle
  return (
    <div className='sitebg'>
      <SiteRouter dark={dark} toggleDark={toggleDark} canEdit={canEdit} options={options} loading={loading} tags={tags} results={results} dark={dark} />
    </div>
  );

}

export default App;
