import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiCall } from "../utils/Api";
import { Breadcrumb, Checkbox, Col, Divider, message, Row, Space, Spin, Tag, Typography } from "antd";
import Html from "../utils/Html";
import moment from "moment";
import Paginate from "./Paginate";
import { ucfirst } from "../utils/Utils";

export default function SearchResults() {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([])
    const [paginate, setPaginate] = useState({
        current: 1,
        pagesize: 10,
        offset: 0,
        total: 0,
        results: []
    });

    const [postTypes, setPostTypes] = useState([])

    const { search_term = "" } = useParams();

    const fetchResults = () => {
        setLoading(true)
        apiCall("getSearch", { term: search_term, postTypes: postTypes }, (_status, _result) => {
            if (_status) {
                setResults(_result.filter(item => item.post_type !== "news"));
                setPaginate({ current: 1, pagesize: 10, offset: 0, total: _result.length });
            } else {
                message.error("There was an error");
            }
            setLoading(false)
        });
    }

    useEffect(fetchResults, [postTypes, search_term]);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [paginate])

    const drawItem = (_item) => {
        return (
            <div id={_item.slug}>
                <Typography.Title level={4}>
                    <Row>
                        <Col flex="auto"><a onClick={() => navigate(_item.path)}><Html html={_item.post_title} /></a></Col>
                    </Row>
                </Typography.Title>

                <div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(_item.date).format("MMM Do, YYYY")}</em></div>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <div style={{ "marginTop": "-10px" }}>{getTag(_item.post_type)}</div>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <Html html={_item.post_content} />
                <br /><br />
            </div>
        )
    }

    const getTag = (_tag) => {

        if (_tag === "tutorials") {
            return (<Tag color="#007ef5">Tutorials</Tag>)
        }

        if (_tag === "newsletter") {
            return (<Tag color="#25e299">Newsletters</Tag>)
        }

        if (_tag === "updates") {
            return (<Tag color="#0d152b">Bookmanager Updates</Tag>)
        }

        if (_tag === "documents") {
            return (<Tag color="#c149c0">Documents</Tag>)
        }
        return (<></>)
    }

    const drawItems = () => {
        return results.slice(paginate.offset, paginate.offset + paginate.pagesize).map(item => drawItem(item))
    }

    const drawPath = (_p) => {

        _p = _p.replace("#", "/");

        if (_p.includes("/filter")) {
            // cull filters from breadcrumbs

            _p = _p.split("/filter")[0];
        }

        let items = _p.split("/");

        if (!_p.length > 0) {
            return (<></>);
        }
        let m = [];
        return items.map((itm, ind) => {
            m.push(itm);
            let x = [...m];
            if (itm === "") {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate("/")}>Home</a></Breadcrumb.Item>
            } else {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate(x.join("/"))}>{ucfirst(itm)}</a></Breadcrumb.Item>
            }
        })
    }

    const togglePostTypes = (_type) => {
        if (postTypes.includes(_type)) {
            setPostTypes(postTypes.filter(item => item !== _type))
        } else {
            setPostTypes([_type]);
        }
    }

    return (
        <>
            <div style={{ "padding": "40px", "maxWidth": "1000px" }}>
                <div style={{ "marginTop": "-30px", "marginLeft": "-15px" }}>
                    <Breadcrumb style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}>
                        {drawPath("/search")}
                    </Breadcrumb>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                </div>
                <Spin spinning={loading}>
                    <Typography.Title level={3}>
                        Displaying Results for: <em>{search_term}</em>
                    </Typography.Title>
                    <Space wrap>
                        <Checkbox value="tutorials" onClick={(e) => togglePostTypes(e.target.value)} checked={postTypes.includes("tutorials")}><span style={{ "opacity": "0.6" }}>Tutorials</span></Checkbox>
                        <Checkbox value="newsletter" onClick={(e) => togglePostTypes(e.target.value)} checked={postTypes.includes("newsletter")}><span style={{ "opacity": "0.6" }}>Newsletters</span></Checkbox>
                        <Checkbox value="updates" onClick={(e) => togglePostTypes(e.target.value)} checked={postTypes.includes("updates")}><span style={{ "opacity": "0.6" }}>Bookmanager Updates</span></Checkbox>
                        <Checkbox value="documents" onClick={(e) => togglePostTypes(e.target.value)} checked={postTypes.includes("documents")}><span style={{ "opacity": "0.6" }}>Documents</span></Checkbox>
                    </Space>
                    <br /><br />
                    <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                    <Divider />
                    {drawItems()}
                    <Divider />
                    <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                </Spin>
            </div>
        </>
    )
}