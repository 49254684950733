import { Input, Select, AutoComplete } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function SearchBar(props) {

    const {search_term} = useParams(); 
    const navigate = useNavigate(); 
    const { results = [] } = props;
    const [options, setOptions] = useState([]);
    const [type, setType] = useState([]);

    const search = (_str) => {
        navigate("/search/" + _str);

    }


    return (
        <>
            <div className="light">
                <Input.Search
                    className="headerSearch"
                    onSearch={(e) => search(e)}
                    allowClear
                    enterButton
                    onSelect={(e) => console.log("Select")}
                    style={{ width: '100%' }}
                    placeholder="Search..."
                    options={options}
                    defaultValue={search_term}
                />
            </div>
        </>
    )
}