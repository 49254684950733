import React from "react";
import Html from "../utils/Html";

export default function Home(props){

    const {data = {}} = props; 
    const {
        details = "",
    } = data; 

    return(<Html html={details} />)
}