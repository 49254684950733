export const ucfirst = (string) => {
  if (string.includes("-")) {
    string = string.split("-");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }
  if (string.includes("_")) {
    string = string.split("_");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }
  return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const addCommas = (_num) => {
  return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const trimLastSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}