import React from "react";
import { Row, Col, Pagination, Divider } from "antd";
import { addCommas } from "../utils/Utils";
import conditional from "../utils/conditional";
import Cookies from "js-cookie";
export default function Paginate(props) {

    const {
        save = "",
        paginate = { current: 1, pagesize: 20, offset: 0 },
        setPaginate = () => { },
        show_pagination = true,
        count = 0,
        hide_single = false,
        loading = false,
    } = props;


    const drawShowing = () => {
        let end = paginate.current * paginate.pagesize;
        let start = (paginate.current * paginate.pagesize) - (paginate.pagesize - 1);
        if (end > count) {
            end = count;
        }
        if (count === 0) {
            return "0 of ";
        }
        return addCommas(start) + " to " + addCommas(end);
    }


    const pageChange = (_page, _limit) => {
        let _offset = (_page - 1) * _limit;

        if(_limit !== paginate.pagesize){
           if(save){
            Cookies.set(save, _limit);
           }
        }
        setPaginate({
            pagesize: _limit,
            offset: _offset,
            current: _page
        })
    }



   
    if((hide_single && paginate.pagesize > count)){
        return (<></>);
    }

    

    return (
        <>
            <div className="shim" /><div className="shim" />
            <div>
                <div className="float-flex">
                      <div style={{ "padding": "0px", "fontSize" : "16px" }}>
                            Showing <em style={{ "fontWeight": "500" }}>{drawShowing()}</em> of <em style={{ "fontWeight": "500" }}>{addCommas(count)}</em> results
                        </div> 
                        
                        <div style={{ "textAlign": "right", "marginTop" : "-15px" }}>
                            <div className="shim" /><div className="shim" />
                            <conditional.true value={(show_pagination)} >
                                <nobr><Pagination size="small" hideOnSinglePage current={paginate.current} pageSize={paginate.pagesize} showLessItems={true} total={count} onChange={(_page, _limit) => pageChange(_page, _limit)} /></nobr>
                            </conditional.true>
                            <div className="shim" /><div className="shim" />
                        </div>
                </div>
            </div>
            
        </>

    )
}