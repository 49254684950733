/*

Default Layout - Wraps sections with nav, footer etc

*/

import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, BackTop, Button, Spin, Space } from 'antd';
import Nav from "./Nav";
import { useLocation, useNavigate } from "react-router-dom";
import { trimLastSlash, ucfirst } from "../utils/Utils";
import { CaretLeftFilled, LeftOutlined, MenuOutlined } from '@ant-design/icons';

export default function Default(props) {

    const { loading = true, results = [], options = { links: [] }, section = "", dark = false, noback = false, toggleDark = () => { } } = props;
    const loc = useLocation();
    // const location_pathname = (loc.hash) ? loc.pathname + loc.hash.replace("#", "/") : loc.pathname;
    const location_pathname = trimLastSlash(loc.pathname);
    const current = results.find(item => "/" + section + "/" + item.path === location_pathname) || { ID: 0, unfound: true, heading: "", slug: section, type: section, details: "", date: "", path: "/" + section, parent: 0 }
    const navigate = useNavigate();

    const [menuVisible, setMenuVisible] = useState(false);


    function getSlugFromPath(pathname) {
        const segments = pathname.split('/');
        if (Array.isArray(segments) && segments.length > 0) {
            return segments[segments.length - 1];
        } else {
            return "";
        }
    }


    const handleOrphans = () => {
        // Handle Orphans
        if (section === "tutorials" && current.hasOwnProperty("unfound")) {
            let _split = location_pathname.split("/");
            let _slug = _split[_split.length - 1];
            let _find = results.find(item => getSlugFromPath(item.path) === _slug);
            if (_find) {
                navigate(_find.path);
            } else {
              
                //navigate("/" + section);
            }
        }
    }

    useEffect(handleOrphans, [results])





    const drawMenuBtn = () => {

        if (section === "updates") {
            return (<></>);
        }

        if (section === "tutorials" && getSlugFromPath(location_pathname) === "tutorials") {
            return (<></>)
        }

        return (<Button className="hide" type="primary" onClick={() => setMenuVisible(true)} size="small"><small><small><MenuOutlined /></small></small></Button>)
    }



    const drawBackButton = () => {

        if (noback) {
            return (<>{drawMenuBtn()}</>);
        }

        let _depth = current.path.split("/");
        let _path = "";

        if (current.parent === 0) {
            _path = "/" + current.type;
        } else {
            _path = "/" + current.type + "/" + _depth.slice(0, _depth.length - 1).join("/");
        }

        // no back button on top-level pages
        if (!current.date) { return (<>{drawMenuBtn()}</>); }
        return (
            <div style={{ "marginTop": "3px" }}>
                <Space>
                    {drawMenuBtn()}
                    <Button onClick={() => navigate(_path)} size="small" type="ghost"><small><small><LeftOutlined /></small>&nbsp; Back</small></Button>
                </Space>
            </div>
        )

    }






    return (
        <>
            <BackTop>
                <Button type="primary" ghost shape="circle" size="large" />
            </BackTop>

            <Nav dark={dark} toggleDark={toggleDark} results={results} menu={options.menu} />

            <div className="show" style={{ "height": "70px" }} />
            <Layout>
                <Layout.Content>
                    <Spin wrapperClassName={(loading) ? "adjust" : ""} spinning={loading}>
                        <div className="paddingwrap" style={{ "height": "calc(100vh - 70px)", "margin": "0 auto", "padding": "0 35px" }}>
                            <div className="layoutWrapper" style={{ "marginBottom": "20px" }}>
                                {(!loading && React.cloneElement(props.children, { drawBackButton: drawBackButton, menuVisible: menuVisible, setMenuVisible: setMenuVisible }))}
                            </div>
                        </div>
                    </Spin>
                </Layout.Content>
            </Layout>
        </>
    );

}