/*
    ---- conditional ----
    Html style tags for conditions - just for easy structure
*/

import React from "react";

export default function conditional(){

    return false; 
}

conditional.true = (props) =>{
    const {...other} = props; 
    if(props.value){
        return (<>{other.children}</>)
    } else {
        return (<></>);
    }
}

conditional.string = (props) =>{

    const {...other} = props; 
    // is string and not empty
    if(typeof props.value === 'string' && props.value !== ""){
        return (<>{other.children}</>)
    } else {
        return (<></>);
    }

}

conditional.object = (props) => {

    const {...other} = props; 
    // is object and not null
    if(typeof props.value === 'object' && !Array.isArray(props.value) && props.value !== null && !Object.keys(props.value).length === 0){
        return (<>{other.children}</>)
    } else {
        return (<></>);
    }
}

conditional.array = (props) => {
    const {...other} = props; 
    // is array and not empty
    if(Array.isArray(props.value) && props.value.length>0){
        return (<>{other.children}</>)
    } else {
        return (<></>);
    }
}

conditional.match = (props) => {
    const {...other} = props; 
    // matches expected
    if(props.value === props.check){
        return (<>{other.children}</>)
    } else {
        return (<></>);
    }
}