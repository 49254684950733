/*
    ---- Tags ----
    Display a list of tags and fire event to their parent when they change.
*/

import React from "react";
import { Checkbox} from "antd";
export default function Tags(props){

    // State is passed in from the parent
    const {
        tags = [],
        selectedTags = [],
        setSelectedTags = () =>{},
    } = props; 

    // Change parent state when tags change
    const onChange = (_isSelected, _tag) =>{
       
        if(selectedTags.length === 0){
            if(_isSelected){
                setSelectedTags(selectedTags => [...selectedTags, _tag]);
            }
        } else {
            if(!selectedTags.includes(_tag)){
                setSelectedTags(selectedTags => [...selectedTags, _tag]);
            } else {
                setSelectedTags(selectedTags.filter(item => item !== _tag));
            }
        }
    }

    // Check to see if it's been added to the selectedTags array. 
    const isChecked = (_tag) =>{
        if(selectedTags.length === 0){
            return false; 
        } else {
            if(selectedTags.includes(_tag)){
                return true; 
            } else {
                return false; 
            }
        }
    }

    // Draw the list of tags
    const drawTags = () => {
        return tags.map((item, index) => {
            return (
                <div key={index}>
                    <Checkbox checked={isChecked(item.term_id)} onChange={(e) => onChange(e.target.checked, item.term_id)} key={index}>{item.name}<small style={{"color" : "#AAA"}}> ({item.count})</small></Checkbox>
                    <div className="shim"></div> <div className="shim"></div>
                </div>
            )
        })
    }

    return drawTags();

}