import { Col, Divider, Layout, message, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Html from "../utils/Html";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Paginate from "./Paginate";
import { apiCall } from "../utils/Api";
import Tags from "./Tags";
export default function Updates(props) {

    const navigate = useNavigate();
    const { tags = [] } = props;
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(props.results.filter(item => item.type === "updates"));
    const [selectedTags, setSelectedTags] = useState([]);

    

    const [paginate, setPaginate] = useState({
        current: 1,
        pagesize: 10,
        offset: 0,
        total: results.length,
    });

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [paginate])


    const stripHtmlAndTruncate = (htmlString, maxLength) => {
        // Strip HTML tags
        const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, "");
    
        // Truncate to maxLength
        if (strippedString.length <= maxLength) {
            return strippedString;
        }
    
        // Find the nearest period before maxLength
        let truncatedString = strippedString.slice(0, maxLength);
        const lastPeriodIndex = truncatedString.lastIndexOf(".");
    
        if (lastPeriodIndex > -1) {
            truncatedString = truncatedString.slice(0, lastPeriodIndex + 1);
        } else {
            truncatedString = truncatedString.slice(0, maxLength);
        }
    
        return truncatedString;
    }

    const drawItem = (_item) => {
        return (
            <div id={_item.slug}>
                <Typography.Title level={4}>
                    <Row>
                        <Col flex="auto"><a onClick={() => navigate(_item.path)}><Html html={_item.heading} /></a></Col>
                    </Row>
                </Typography.Title>
                <div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(_item.date).format("MMM Do, YYYY")}</em></div>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                {/* <Html html={_item.details} /> */}
                {stripHtmlAndTruncate(_item.details, 500)}
                <br /><br />
            </div>
        )
    }

    const drawItems = () => {
        return results.slice(paginate.offset, paginate.offset + paginate.pagesize).map(item => drawItem(item));
    }

    return (
        <div style={{ "padding": "40px", "maxWidth": "1000px" }}>
            <Layout style={{ "padding": "0px" }} className="layout">
             
                <Layout.Content>
                    <Spin spinning={loading}>
                        <Typography.Title level={3}>
                            Updates
                        </Typography.Title>
                        <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                        <Divider />
                        {drawItems()}
                        <Divider />
                        <Paginate setPaginate={(e) => setPaginate({ ...paginate, "pagesize": e.pagesize, "offset": e.offset, "current": e.current })} count={paginate.total} paginate={paginate} />
                    </Spin>
                </Layout.Content>
            </Layout>
        </div>
    );
}